import React from "react";
import { zoom, mail, slack } from "../image";

export function Connect(props) {
  // console.log("preferCommunication : " + preferCommunication );

  return (
    <div ref={props.prefCommunicationRef} className="flex">
      <div>
        <div className="p-0 w-full max-w-screen-xl flex  justify-center flex-wrap">
          <div className="text-center w-full">
            <h2 className="text-white text-5xl font-medium">
              Feel Free to Connect with us!
            </h2>
            <p className="text-white text-xl mt-7 font-normal max-w-2xl w-full mx-auto">
              schedule a meeting with us
            </p>
          </div>
          <div className="w-full mt-10 space-y-9">
            <div className="w-full my-5 space-y-9">
              <div className="flex justify-between mobile:flex-col">
                <div className="relative w-full p-0 h-[430px] flex flex-wrap mobile:mb-8">
                  <div className="relative w-full h-auto flex justify-center ">
                    <label htmlFor="zoom">
                      <div className="bg-white hover:cursor-pointe p-9 h-44 w-44 rounded-full flex items-center justify-center mobile:mx-auto">
                        <img
                          className="hover:cursor-pointer"
                          src={zoom}
                          alt=""
                        />
                      </div>
                      <input
                        id="zoom"
                        type="radio"
                        name="connect-radio"
                        value={1}
                        // checked={props.value === 1}
                        className="w-6 h-6 border-gray-300 checked:bg-orange-300 checked:border-orange-600 focus:border-8 focus:border-orange-400 absolute bottom-[24px] right-0 left-0 mx-auto"
                        onChange={props.handleChange}
                      ></input>
                    </label>
                  </div>
                  <div className="max-w-xs mx-auto w-full">
                    <div className="w-full relative text-center">
                      <p className="text-white text-lg my-6 font-medium">
                        Zoom
                      </p>
                    </div>
                    <p className="text-white font-light text-xl  text-justify">
                      Set schedule with us for Development Projection Meeting,
                      lets talk more about your project, and its free!{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
