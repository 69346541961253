import React from "react";
import arrow from "../assets/img/arrow-flow.svg";
import Schedule from "../assets/img/schedule.svg";
import design from "../assets/img/design.svg";
import Layout2 from "../layout/Layout2";
import { Modal } from "reactstrap";
import { Modals } from "../components/Modal";

export function SuccessSchedule() {
  const [modal, setModal] = React.useState(false);

  const toggle = () => setModal(modal);

  return (
    <div>
      <Layout2>
        <section className="w-full relative">
          <div className="h-screen relative w-full bg-blue-600">
            <div className="flex mx-auto relative flex-wrap lg:flex-nowrap z-10">
              <div className=" items-center w-full flex flex-wrap lg:flex-nowrap ">
                <div className="md:w-full relative mx-auto pr-[20px] rounded-md flex">
                  <div className="p-0 w-full relative flex">
                    <div className="p-0 flex landing_flex_flow relative w-full content-start">
                      <section className="relative w-full">
                        <div className="w-full flex flex-wrap lg:flex-nowrap  mx-auto relative">
                          <div className="flex items-center w-full flex-wrap lg:flex-nowrap mt-20 mobile:mt-10">
                            <div className="w-full md:w-2/4 pr-10 pl-0 md:pl-0 md:pr-2 relative flex order-2 md:order-1">
                              <div className="p-0 w-full relative flex">
                                <Modals isOpen={modal} toggle={toggle}>
                                  Invitation has been sent to your email
                                </Modals>
                                <div className="p-0 relative flex w-full z-10 mt-24">
                                  <div className="flex relative flex-wrap w-full items-start p-0">
                                    <div className="w-full mb-0 mt-3 ml-20">
                                      <div>
                                        <h2 className="mb-5 p-0 font-regular text-4xl md:text-6xl text-white">
                                          THANKS FOR SCHEDULING A CALL WITH
                                          BLANKONTECH
                                        </h2>
                                      </div>
                                    </div>
                                    <div className="relative w-full">
                                      <div className="w-full my-8 absolute left-0">
                                        <div>
                                          <img src={arrow} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-full mb-0 ml-20 mt-32">
                                      {/* <h2 className="mb-5 p-0 font-regular text-white text-3xl">
                                        You’ll be meeting with blankontech at
                                        10:00am - sunday, august 8, 2022,
                                        meeting details will be sent to email
                                      </h2> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-full justify-center md:justify-start md:w-2/4 pb-0 lg:pb-10 md:pb-0 md:pr-10 relative flex mt-0 order-1 md:order-2 z-10 hidden md:block">
                              <div className="p-0 w-full relative flex">
                                <div className="p-0 relative flex w-full">
                                  <div className="flex relative flex-wrap w-full items-start p-0 h-auto">
                                    <img
                                      src={Schedule}
                                      alt=""
                                      className="h-full w-full"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="top-0 right-0 left-0 bottom-0 overflow-visible absolute mb-10 hidden_badge_image h-screen z-0 hidden md:block">
              <div className="relative h-full">
                <div className="p-0 w-full h-full max-h-[none] overflow-hidden z-[1px] max-w-full absolute">
                  <div className="w-full h-full">
                    <img src={design} alt="" className="w-full h-full" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout2>
    </div>
  );
}
