import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CheckCircleFilled } from "@ant-design/icons";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import "../assets/partnerships.css";
import { InlineWidget } from "react-calendly";

import Layout from "../layout/Layout";
import box from "../assets/img/kotak.svg";
import success from "../assets/img/success.svg";
import { Contact } from "../components/Contact";
import { Hero } from "../components/Options/Hero";
import { Connect } from "../components/Options/Connect";
import LongTextNew from "../components/Options/LongTextNew.jsx";
import { Modals } from "../components/Modal";

export function PageOptionsnew() {
  let navigate = useNavigate();
  const [preferCommunication, setPreferCommunication] = useState("");
  const [submitDisplay, setSubmitDisplay] = React.useState("");

  const handlePreferCommunication = (e) => {
    setPreferCommunication(e.target.value);
  };

  // Get param package choice and check choice
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const packageCoiced = parseInt(params.package);

  useEffect(() => {
    setPreferCommunication(preferCommunication);
    if (preferCommunication === "1") {
      setSubmitDisplay("");
    } else {
      setSubmitDisplay("flex");
    }
  }, [preferCommunication]);
  // const [contact, setContact] = useState({});
  // console.log("submitDisplay: " + submitDisplay);

  const [referenceBy, setReferenceBy] = useState("Instagram");
  const handleReference = (e) => {
    setReferenceBy(e.target.value);
  };

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [longtext, setLongtext] = React.useState("");
  const [others, setOthers] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  // console.log("Others: " + others);

  const handleOthers = (e) => {
    setOthers(e.target.value);
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleChangeCountry = (e) => {
    setCountry(e.target.value);
  };

  const handleChangeLongText = (e) => {
    setLongtext(e.target.value);
  };

  const [modal, setModal] = React.useState(false);

  const handleToggleModal = () => {
    setModal(false);
    navigate("/");
    window.location.reload(true);
    window.scrollTo(0, 0);
  };

  const handleModal = () => {
    if (preferCommunication === "1") {
      navigate("/thanks-for-scheduling");
    } else {
      setModal(true);
    }
  };

  const reference = referenceBy === "Others" ? others : referenceBy;
  // console.log("Reference: " + reference);
  // console.log("REACT_APP_API_ENDPOINT : ");
  // console.log(process.env.REACT_APP_API_ENDPOINT);

  const handlePost = (event) => {
    event.preventDefault();
    setLoading(true);
    var affiliate_code = null;
    try {
      affiliate_code = document.cookie.match(
        new RegExp(
          process.env.REACT_APP_ENVIRONTMENT + "_partner_code" + "=([^;]+)"
        )
      )[1];
    } catch (error) {
      if (process.env.REACT_APP_ENVIRONTMENT !== "production") {
        console.log("no partner code in cookie, see error bellow!");
        console.log(error);
      }
    }

    let data = {
      contact: {
        name: name,
        email: email,
        country: country,
      },
      type: 1,
      longtext: longtext,
      reference: reference,
      preferred_communication: parseInt(preferCommunication),
      affiliate_code: affiliate_code,
    };

    if (packageCoiced) {
      data.package = packageCoiced;
    }

    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}`, data)
      .then((res) => {
        console.log(res);
        console.log(res.data);
        handleModal();
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.response.data);
        if (error.response.data.email) {
          goToContact();
          toast("⚠️ Email is required");
        } else if (error.response.data.country) {
          goToContact();
          toast("⚠️ Country is required");
        } else if (error.response.data.name) {
          goToContact();
          toast("⚠️ Name is required");
        }
        setLoading(false);
      });
  };

  const ref = useRef();
  const Animation = { top: ref.offsetTop, left: 0, behavior: "smooth" };

  const contactRef = useRef(null);
  const longTextRef = useRef(null);
  const knowRef = useRef(null);
  const preferCommunicationRef = useRef(null);
  const submitRef = useRef(null);

  const goToContact = () => contactRef.current.scrollIntoView(Animation);
  const goToLongText = () => longTextRef.current.scrollIntoView(Animation);
  const goToKnow = () => knowRef.current.scrollIntoView(Animation);
  const goToPreferCommunication = () =>
    preferCommunicationRef.current.scrollIntoView(Animation);
  const gotToSubmit = () => submitRef.current.scrollIntoView(Animation);

  const getAffiliateCode = () => {
    try {
      return document.cookie.match(
        new RegExp(
          process.env.REACT_APP_ENVIRONTMENT + "_partner_code" + "=([^;]+)"
        )
      )[1];
    } catch (error) {
      if (process.env.REACT_APP_ENVIRONTMENT !== "production") {
        console.log("no partner code in cookie, calendly set to -");
      }
      return "-";
    }
  };

  let emailReg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const contactSubmitted = () => {
    if (name === "") {
      toast("⚠️ Name is required");
    } else if (email === "") {
      toast("⚠️ Email is required");
    } else if (emailReg.test(email) === false) {
      toast("⚠️ Invalid Email");
    } else if (country === "") {
      toast("⚠️ Country is required");
    } else {
      goToLongText();
    }
  };

  const handleKnow = () => {
    if (referenceBy === "Others") {
      if (others === "") {
        toast("⚠️ Others is required");
      } else {
        goToPreferCommunication();
      }
    } else {
      goToPreferCommunication();
    }
  };

  const preferCommunicationSubmitted = () => {
    if (preferCommunication === "") {
      toast("⚠️ Connect with is required");
    } else {
      gotToSubmit();
    }
  };

  useEffect(() => {
    if (window.screen.width > 960) {
      if (
        process.env.REACT_APP_ENVIRONTMENT === "production" ||
        process.env.REACT_APP_ENVIRONTMENT === "staging"
      ) {
        document.body.style.overflow = "hidden";
      }
    } else {
      document.body.style.overflow = "scroll";
    }
    // document.body.style.overflow = "hidden";
  }, []);

  return (
    <div className="m-0 p-0 box-border" id="inquiries-scroll">
      <Toaster />
      <div className="relative w-screen ">
        <Layout>
          <div className="absolute top-0 right-0 z-10">
            <img src={box} alt="" />
          </div>
          <div className="bg-hero-100 w-screen overflow-x-hidden  h-screen ">
            <Hero handleSubmit={goToContact} />
          </div>
          <div className="flex h-screen bg-blue-600 ">
            <div className="m-auto mobile:mx-2">
              <Contact
                contactRef={contactRef}
                name={name}
                handleChangeName={handleChangeName}
                email={email}
                handleChangeEmail={handleChangeEmail}
                country={country}
                handleChangeCountry={handleChangeCountry}
                handleSubmit={contactSubmitted}
              />
            </div>
          </div>
          <div className="bg-blue-600 w-screen h-auto overflow-x-hidden pb-9">
            <section className="flex mx-auto relative flex-wrap lg:flex-nowrap mt-32 z-10">
              <div className="items-center w-full flex flex-wrap lg:flex-nowrap ">
                <div className="contaner mx-auto">
                  <div className="flex h-screen">
                    <div className="m-auto mobile:mx-2">
                      <LongTextNew
                        longtextRef={longTextRef}
                        handleText={handleChangeLongText}
                        longtext={longtext}
                        handleSubmit={goToKnow}
                        backSubmit={goToContact}
                      />
                    </div>
                  </div>

                  <div ref={knowRef} className="p-0 w-full flex my-48 h-screen">
                    <div className="m-auto mobile:mx-2">
                      <div className="p-0 w-full max-w-3xl mx-auto relative flex justify-center flex-wrap">
                        <div className="text-center w-full">
                          <h2 className="text-white text-5xl font-medium">
                            How do you know about blankon?
                          </h2>
                          <p className="text-white text-xl mt-7 font-normal max-w-2xl w-full mx-auto">
                            from whom or what channel did you find out about
                            blankon
                          </p>
                        </div>
                        <div className="w-full mb-5 mt-10 space-y-9">
                          <div className="w-full my-5 space-y-9">
                            <div className="">
                              <select
                                className="form-select form-select-xl mb-5 appearance-none block w-full px-4 py-2 h-16 font-medium text-gray-700 
                              bg-white bg-clip-padding bg-no-repeat rounded-lg border-solid border-2  transition  ease-in-out m-0
                               focus:text-gray-700 focus:bg-white focus:border-blue-600 border-purple-800 focus:outline-none"
                                value={referenceBy}
                                onChange={handleReference}
                              >
                                <option value="Instagram">Instagram</option>
                                <option value="Linkedin">Linkedin</option>
                                <option value="Twitter">Twitter</option>
                                {/* <option value='Others Social Media'>
                                  Others Social Media
                                </option> */}
                                <option value="Friend/Family">
                                  Friend/Family
                                </option>
                                <option value="Others">Others</option>
                              </select>
                              {referenceBy === "Others" ? (
                                <div className="w-full my-5 space-y-9 max-w-3xl mx-auto">
                                  <div>
                                    <span className="text-white text-xl flex mb-5">
                                      Others
                                      <p className="text-red-500 ml-2">*</p>
                                    </span>
                                    <input
                                      type="text"
                                      placeholder="Type Here"
                                      className="bg-white p-4 border-2 border-purple-800 rounded-lg w-full mobile:w-full h-20 placeholder:text-slate-400 placeholder:font-medium"
                                      name="others"
                                      value={others}
                                      maxLength="30"
                                      onChange={handleOthers}
                                      required
                                    />
                                    <p
                                      id="helper-text-explanation"
                                      class="mt-2 text-sm text-white"
                                    >
                                      Maximum 30 Characters
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="w-full flex justify-center">
                            <button
                              onClick={goToLongText}
                              className="border mr-4 bg-transparent border-white p-4 h-14 w-48 text-base font-medium rounded-lg text-white hover:text-blue-600 hover:bg-white"
                            >
                              back
                            </button>
                            <button
                              onClick={handleKnow}
                              className="border bg-transparent border-white p-4 h-14 w-48 text-base font-medium rounded-lg text-white hover:text-blue-600 hover:bg-white"
                            >
                              continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex md:h-screen">
                    <div className="mx-auto">
                      <Connect
                        prefCommunicationRef={preferCommunicationRef}
                        value={preferCommunication}
                        handleChange={handlePreferCommunication}
                        // handleSubmit={preferCommunicationSubmitted}
                        // handleBack={goToKnow}
                      />
                      <div className="w-full flex justify-center mt-2 mb-10">
                        <button
                          onClick={goToKnow}
                          type="button"
                          className="border mr-4 bg-transparent border-white p-4 h-14 w-48 text-base font-medium rounded-lg text-white hover:text-blue-600 hover:bg-white"
                        >
                          back
                        </button>
                        {preferCommunication === "1" ? (
                          <button
                            onClick={preferCommunicationSubmitted}
                            type="button"
                            className="border bg-transparent border-white p-4 h-14 w-48 text-base font-medium rounded-lg text-white hover:text-blue-600 hover:bg-white"
                          >
                            continue
                          </button>
                        ) : preferCommunication === "2" ||
                          preferCommunication === "3" ? (
                          <>
                            {loading ? (
                              <button
                                disabled
                                type="button"
                                class="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                              >
                                <svg
                                  role="status"
                                  class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="#1C64F2"
                                  />
                                </svg>
                                Loading...
                              </button>
                            ) : (
                              <button
                                onClick={handlePost}
                                type="button"
                                className="border bg-transparent border-white p-4 h-14 w-48 text-base font-medium rounded-lg text-white hover:text-blue-600 hover:bg-white"
                              >
                                Submit Your Idea
                              </button>
                            )}
                          </>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={`h-screen ${submitDisplay}`} ref={submitRef}>
                    <div className="m-auto">
                      <div className="container">
                        {preferCommunication === "1" ? (
                          <div className="w-full mx-auto bg-blue-600 mb-4">
                            <InlineWidget
                              styles={{
                                height: "650px",
                              }}
                              url="https://calendly.com/blankontech/development-projection-meeting?hide_gdpr_banner=1"
                              prefill={{
                                customAnswers: {
                                  a2: getAffiliateCode(),
                                },
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="w-full flex  justify-center">
                        {loading ? (
                          <button
                            disabled
                            type="button"
                            class="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                          >
                            <svg
                              role="status"
                              class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="#1C64F2"
                              />
                            </svg>
                            Loading...
                          </button>
                        ) : (
                          <button
                            onClick={handlePost}
                            className="border-2 rounded-lg py-4 px-12 text-3xl text-white hover:bg-white hover:text-blue-600 hover:font-semibold"
                          >
                            Submit Your Idea
                          </button>
                        )}
                      </div>
                      <div className="w-full flex justify-center mt-4">
                        <button
                          onClick={goToPreferCommunication}
                          className="border bg-transparent border-white p-4 h-14 w-48 text-base font-medium rounded-lg text-white hover:text-blue-600 hover:bg-white"
                        >
                          back
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="p-0 w-full relative my-48">
                    <div className="p-0 w-full max-w-screen-xl mx-auto relative flex justify-center flex-wrap space-y-12">
                      <div className="text-center w-full">
                        <h2 className="text-white text-7xl mobile:text-4xl font-medium">
                          Thank you for expressing your interest in Blankontech
                        </h2>
                        <p className="text-white text-4xl mt-7 font-normal max-w-2xl w-full mx-auto">
                          can't wait to connect with you
                        </p>
                      </div>
                      <div>
                        <img
                          src={process.env.REACT_APP_PUBLIC_URL + success}
                          alt=""
                        />
                      </div>
                      <div className="w-full flex justify-center">
                        <button className="border bg-transparent border-white mobie:w-full p-4 h-14  text-base font-medium rounded-lg text-white hover:text-blue-600 hover:bg-white">
                          back to blankontech
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Modals isOpen={modal} toggle={handleToggleModal}>
              Invitation has been sent to your email
            </Modals>
          </div>
        </Layout>
      </div>
    </div>
  );
}
