import hero from "../../assets/img/hero 1.png";

export function Hero(props) {
  return (
    <section ref={props.heroRef} id="Schedule" className=" h-screen ">
      <div className="flex mx-auto relative flex-wrap h-screen lg:flex-nowrap mobile:h-screen ">
        <div className="items-center w-full flex flex-wrap lg:flex-nowrap ">
          <div className="md:w-full relative max-w-screen mx-auto px-[20px] rounded-md flex">
            <div className="p-0 w-full relative flex">
              <div className="p-0 flex landing_flex_flow relative w-full content-start">
                <section className="relative w-full mobile:mx-auto">
                  <div className="mx-auto w-full mr-40 ml-40 mobile:m-0">
                    <div className="flex items-center w-full flex-wrap lg:flex-nowrap">
                      <div className="w-11/12 mobile:w-full mobile:p-0 pr-10 pl-10 md:pl-0 md:pr-2 relative flex order-2 md:order-1">
                        <div className="p-0 w-full relative flex">
                          <div className="p-0 relative flex w-full z-10">
                            <div className="flex relative flex-wrap w-full items-start p-0">
                              <div className="w-full mb-0 mt-3">
                                <div>
                                  <h2 className="lg:text-6xl text-5xl text-transparent bg-clip-text bg-gradient-to-r from-blankon-tosca to-blankon-green font-semibold mb-4">
                                    Building Revolutionary Product With
                                    Blankontech
                                  </h2>
                                </div>
                              </div>
                              <div className="w-8/12 mb-2 mt-3">
                                <div>
                                  <p className="mb-5 p-0 text-2xl font-light leading-relaxed text-white">
                                    Tell us about your project and what you
                                    need! Lets build a very high quality product
                                    together
                                  </p>
                                </div>
                              </div>
                              <div className="w-full mb-0 mobile:mb-10">
                                <button
                                  onClick={props.handleSubmit}
                                  className="rounded-full py-4 px-8 bg-indigo-600 w-56 h-auto text-center"
                                >
                                  <p className="text-white text-lg">
                                    spill out your idea
                                  </p>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full mr-52 justify-center md:justify-start pb-0 lg:pb-10 md:pb-0 relative flex mt-0 order-1 md:order-2 z-10">
                        <div className="p-0 w-full relative flex">
                          <div className="p-0 relative flex w-full">
                            <div className="flex relative flex-wrap w-full items-start p-0 h-full">
                              <img
                                src={hero}
                                alt=""
                                className="h-[692px] w-[838px] mobile:sr-only"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
