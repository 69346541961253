import React from "react";
import { Collapse } from "react-collapse";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import classNames from "classnames";
import { data } from "../data/faqs";

type State = {
  loading: boolean,
  activeIndex: any,
  data: any,
};

export default class CollapseFaqs extends React.Component<null, State> {
  state = {
    data: [],
    activeIndex: null,
    loading: false,
  };

  componentDidMount() {
    this.setState({
      data: data,
    });
  }

  toggleClass = (index, e, information) => {
    this.setState({
      activeIndex: this.state.activeIndex === index ? null : index,
    });
  };

  render() {
    const { data, activeIndex, loading } = this.state;
    let content = "";
    if (loading) {
      content = (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      );
    } else {
      content = data.map((information, index) => {
        return (
          <div key={index}>
            <div
              onClick={this.toggleClass.bind(this, index, information)}
              className='border-b-2 border-gray-800 pb-4 cursor-pointer mb-6'
            >
              <span
                className={
                  activeIndex === index
                    ? "font-medium text-2xl text-gray-800"
                    : "font-medium text-gray-400 text-2xl"
                }
              >
                {information.title}
              </span>
            </div>
            <Collapse isOpened={activeIndex === index}>
              <div
                className={classNames("mt-5 mb-5", {
                  show: activeIndex === index,
                  hide: activeIndex !== index,
                })}
              >
                {/* <p className="font-medium whitespace-normal text-gray-600 text-lg break-word text-justify">
                  {information.title}{" "}
                </p> */}
                {/* <br /> */}
                {activeIndex === index && information.detail ? (
                  <div>
                    <p className='font-medium whitespace-normal text-gray-800 text-xl break-word text-justify my-4'>
                      {information.detail}{" "}
                      <a
                        className='hover:text-blue-500 transition-all duration-200 border-b-2 border-white hover:border-blue-500'
                        target='_blank'
                        rel='noreferrer'
                        href={information.link}
                      >
                        {information.link}
                      </a>
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Collapse>
          </div>
        );
      });
    }
    return <div>{content}</div>;
  }
}
