export function FaqHeader() {
  return (
    <section>
      <div className="bg-gray-200 rounded-xl">
        <div className="lg:container mx-auto pt-24 pb-12 py-12 md:py-20">
          <div className="space-y-4">
            <h4 className="font-medium text-6xl">FAQs</h4>
          </div>
        </div>
      </div>
    </section>
  );
}
