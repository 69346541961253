import { Footer } from "./Footer";
import { NavbarWhite } from "./NavbarWhite";

function Layout({ children, className }) {
  return (
    <div className='min-h-screen'>
      <div className='absolute top-0 z-30 w-full'>
        <div className={`max-w-screen-2xl mx-auto px-4 sm:px-6 ${className}`}>
          <NavbarWhite />
        </div>
      </div>
      <div>{children}</div>
      <div className='max-w-5xl mx-auto px-4 sm:px-6'>
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
