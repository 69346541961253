import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Modals({ isOpen, toggle, children }) {
  return (
    <>
      {isOpen ? (
          <div className="overflow-y-auto overflow-x-hidden flex justify-center items-center fixed top-0 right-0 bottom-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full bg-gray-900 bg-opacity-40">
            <div className="relative p-4 w-full max-w-2xl mx-auto h-full md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-white">
                <div className="bg-green-500 rounded-t-lg text-center">
                  <div className="flex justify-between items-start p-5 rounded-t-lg">
                    <button
                      type="button"
                      onClick={toggle}
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <svg
                        className="w-5 h-5 text-white"
                        fill="#fff"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div className="p-6 text-white flex justify-center">
                    <div className="flex flex-col">
                      <div className="mb-6 flex flex-col">
                        <div className="bg-white py-7 px-5 mx-24 rounded-md flex items-center justify-center text-green-600 text-5xl mb-4">
                          <FontAwesomeIcon icon={faCheck} />
                        </div>
                        <h3 className="font-medium text-4xl">Awesome</h3>
                      </div>
                      <div className=" max-w-xl">{children}</div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center p-6 space-x-2">
                  <button
                    type="button"
                    onClick={toggle}
                    className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:green-blue-700 dark:focus:ring-green-800"
                  >
                    Accept
                  </button>
                </div>
              </div>
            </div>
          </div>
      ) : null}
    </>
  );
}
