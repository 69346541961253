import { IconLayout } from "./IconLayout";

export function Footer() {
  return (
    <footer className="pt-10 pb-24 md:pt-16 md:pb-20">
      <div className="container">
        <div className="justify-between md:flex">
          <div className="text-center lg:w-4/12 md:text-left">
            <div className="flex items-center justify-center md:justify-start mb-8">
              <img
                className="h-12 w-12"
                alt=""
                src="https://res.cloudinary.com/blankontech-com/image/upload/v1632285400/blankontech_sp1axc.png"
              />
              <div className="ml-2">
                <h3 className="font-bold text-xl">Blankontech</h3>
              </div>
            </div>
            <h3 className="mb-8 text-2xl md:max-w-xs u-h3 font-semibold leading-10">
              Blankon Technology Solutions
            </h3>
            <h3 className="mb-8 text-2xl md:max-w-xs u-h3 font-semibold leading-10">
              Software developer partner
            </h3>
            <br />
            <span className="leading-4 text-base">
              PT. Perintis Teknologi Internasional info@blankontech.com Jl.
              Villa Bukit Tidar Blk. C1 No.250, Merjosari, Kec. Lowokwaru, Kota
              Malang, Jawa Timur 65114
            </span>
            <IconLayout className="my-8 md:justify-start justify-center flex" />
            <a
              className="text-lg transition-opacity duration-200 ease-in-out opacity-50 hover:opacity-100 font-semibold mt-8"
              href="/privacy-policy"
            >
              Privacy Policy
            </a>
          </div>
          <div className="pt-12 mt-12 border-t border-dashed md:w-7/12 md:pl-12 lg:pl-24 md:border-l-2 md:mt-0 md:pt-0 md:border-t-0">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-8 lg:gap-y-16 lg:grid-cols-3">
              <div>
                <h4 className="text-xl font-semibold">Join Partner</h4>
                <div className="mt-5 space-y-2">
                  <ul className="list-none">
                    <li>
                      <a
                        href="https://partners.blankontech.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-xl transition-opacity duration-200 ease-in-out opacity-50 hover:opacity-100"
                      >
                        Partner
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <h4 className="text-xl font-semibold">Company</h4>
                <div className="mt-5 space-y-2">
                  <ul className="list-none">
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://blog.blankontech.com"
                        className="text-xl transition-opacity duration-200 ease-in-out opacity-50 hover:opacity-100"
                      >
                        Blog
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div className="space-y-2">
                  <ul className="list-none">
                    <li>
                      <a
                        href="/Blankontech-FAQs"
                        className="text-xl transition-opacity duration-200 ease-in-out opacity-50 hover:opacity-100 font-semibold hover:underline-offset-8"
                      >
                        FAQs
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 text-center md:mt-20 md:text-left">
          <div className="lg:w-7/12 md:pl-24 md:ml-auto md:flex">
            <p className="text-lg opacity-50">
              © {new Date().getFullYear()} BlankonTech
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
