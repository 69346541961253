import React from "react";

export function Contact(props) {


  return (
    <form ref={props.contactRef} className='w-full flex h-screen'>
      <div className='m-auto'>
        <div className='text-center w-full'>
          <h2 className='text-white text-5xl font-medium'>
            where can i contact you?
          </h2>
        </div>
        <div className='w-full my-5 space-y-9 max-w-3xl mx-auto'>
          <div>
            <span className='text-white text-xl flex mb-5'>
              Name<p className='text-red-500 ml-2'>*</p>
            </span>
            <input
              type='text'
              placeholder='John Doe'
              className='bg-white p-4 border-2 border-purple-800 rounded-lg w-full mobile:w-full h-20 placeholder:text-slate-400 placeholder:font-medium'
              name='name'
              id=''
              value={props.name}
              onChange={props.handleChangeName}
              required
            />
          </div>
          <div>
            <span className='text-white text-xl flex mb-5'>
              Email<p className='text-red-500 ml-2'>*</p>
            </span>
            <input
              type='email'
              placeholder='example@gmail.com'
              className='bg-white p-4 border-2 border-purple-800 rounded-lg w-full mobile:w-full h-20 placeholder:text-slate-400 placeholder:font-medium'
              name='email'
              id=''
              value={props.email}
              onChange={props.handleChangeEmail}
              required
            />
          </div>
          <div>
            <span className='text-white text-xl flex mb-5'>
              In what country do you live?
              <p className='text-red-500 ml-2'>*</p>
            </span>
            <input
              type='text'
              placeholder='example: England'
              className='bg-white p-4 border-2 border-purple-800 rounded-lg w-full mobile:w-full h-20 placeholder:text-slate-400 placeholder:font-medium'
              name='country'
              id=''
              value={props.country}
              onChange={props.handleChangeCountry}
              required
            />
          </div>
        </div>
        <div className='w-full flex justify-center mt-20'>
          <button
            onClick={props.handleSubmit}
            type='button'
            className='border bg-transparent border-white p-4 h-14 w-48 text-base font-medium rounded-lg text-white hover:text-blue-600 hover:bg-white'
          >
            continue
          </button>
        </div>
      </div>
    </form>
  );
}
