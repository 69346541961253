import React from "react";
import Home from "../layout/Home";
import { PrivacyBody } from "./body/privacyBody";
import { HeaderPrivacy } from "./header/HeaderPrivacy";

export default class PrivacyPolicy extends React.Component {
  render() {
    return (
      <Home>
        <section>
          <div>
            <HeaderPrivacy />
            <div className='container mx-auto'>
              <h2 className='text-center md:text-left font-bold text-3xl font-sora text-gray-800 mt-12 '>
                Blankontech Privacy Policy
              </h2>
              <div className='mt-12'>
                <p className='font-normal text-lg text-gray-800'>
                  This Privacy Policy governs the manner in which BlankonTech
                  (“We”, “Us”, “Our”, “Our Company” or the “Website”), use,
                  maintains and discloses information collected from users or
                  from client (each, a “User”) of the{" "}
                  <span>
                    <a
                      className='inline-block transition-all duration-200  border-b-2 border-white hover:font-semibold hover:border-blue-500'
                      href='/'
                    >
                      www.blankontech.com
                    </a>
                  </span>{" "}
                  website (“Site”)
                </p>
                <br />
                <span className='font-normal text-lg text-gray-800 leading-relaxed break-words'>
                  We are fully aware of the importance of your privacy. We
                  would, therefore, like to take this opportunity to give you a
                  quick overview about our security measures and how we protect
                  your personal data
                </span>
                <br />
                <br />
                <span className='font-normal text-lg text-gray-800 leading-relaxed break-words flex justify-center'>
                  This Privacy Policy explains how information about you is
                  collected, used and disclosed by Blankontech.com and our
                  subsidiaries when you use our website or when you otherwise
                  interact with us. Regarding the Service, we comply with the
                  applicable laws and regulations.
                </span>
                <br />
                <span className='font-normal text-lg text-gray-800 leading-relaxed break-words flex justify-center'>
                  As you use our Service we want you to know how we collect,
                  store and process your information. We created this Privacy
                  Policy for you to get all the information you need to protect
                  your privacy. We would like you to carefully read all the
                  information we provide you and if there are any questions
                  left, please do not hesitate to contact us at any time. For
                  further privacy policy information, please contact:
                  info@blankontech.com
                </span>
              </div>
            </div>
          </div>
        </section>
        <PrivacyBody />
        <div className='border-t-4 border-gray-500 mt-10 pt-10 container mx-auto'>
          <h4 className='font-medium text-base'>Last Updated: Apr 19, 2022</h4>
        </div>
      </Home>
    );
  }
}
