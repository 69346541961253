export function PrivacyBody() {
  return (
    <section>
      <div className="container mx-auto">
        <div className="mt-3">
          <h2 className="text-lg font-medium">
            1. What kind of information qualifies as Personal Data?
          </h2>
          <p className="mt-4 text-base leading-7">
            “Personal Data” shall mean any information relating to an identified
            or identifiable natural person (‘Data Subject’), e.g. your name,
            your address, your telephone number or your e-mail address. An
            identifiable person is one who can be identified, directly or
            indirectly, in particular by reference to an identification number
            or to one or more factors specific to his physical, physiological,
            mental, economic, cultural or social identity.
          </p>
        </div>
        <div className="mt-3">
          <h2 className="text-lg font-medium">
            2. What kind of data do we collect?
          </h2>
          <p className="mt-4 text-base leading-7">
            We collect data you directly provide us with when submitting any
            kind of information to us, e.g. when you set a schedule call with
            us. Even though you do not directly provide us with any Personal
            Data we may collect anonymous information about you, such as data
            from Calendly;{" "}
            <a
              href="https://calendly.com/"
              className="inline-block transition-all duration-200  border-b-2 border-white hover:font-semibold hover:border-blue-500"
            >
              https://calendly.com/
            </a>
          </p>
          <ul>
            <li className="font-medium my-2 text-base">
              Information you submit to us
            </li>
            <li>
              The use of our Website and Service requires you to set schedule
              from blankontech.com. When you set schedule, we will ask you for
              your Personal Data, in particular for your name, and email
              address.
            </li>
          </ul>
        </div>
        <div className="mt-3">
          <h2 className="text-lg font-medium">
            3. How will the information be used by us?
          </h2>
          <p className="mt-4 text-base leading-7">
            Unless you give us your separate and express consent to other uses
            of your Personal Data, we collect, store and process the Personal
            Data that you submit to us only to the extent required for you to
            use our Service, or to the extent that such use is allowed in
            accordance with other legal provisions, even without your consent,
            in particular for responding to your inquiries.
          </p>
          <p className="mt-4 text-base leading-7">
            The Personal Data you directly submit to us will only be used to
            identify you as a customer of our Service, to contact you if
            necessary and to issue invoices. We will store all Personal Data you
            submitted directly to us in our database. All Personal Data will be
            handled by Blankontech in strict confidence according to legal
            provisions.
          </p>
          <p className="mt-4 text-base leading-7">
            Besides, we only collect anonymous information about you to provide
            better service to all of our users. We will use the anonymous
            information to evaluate our Service activities and improve our
            Service.
          </p>
        </div>
        <div className="mt-3">
          <h2 className="text-lg font-medium">4. Will we share your data?</h2>
          <p className="mt-4 text-base leading-7">
            We will only transfer or process personal data to third parties if
            this is required for statutory or legal reasons and/or if we have
            your explicit consent to do so. The third party we share the data
            with is partners.blankontech.com and Blankon Tech Solutions, as
            provides end users access to your products and services that are
            created with ; another party we share your data with is the author
            and/or owner which created the products and services you subscribed
            to, in order for him/her to provide the necessary services for you.
          </p>
          <p className="mt-4 text-base leading-7">
            We shall be entitled to collect, process and use your personal data
            for the purpose of identifying, delimiting and removing malfunctions
            and errors in the Service. If the requirements are met, we may
            collect, process and use personal data that is necessary to uncover
            any unlawful use of the Service. In accordance with prevailing legal
            provisions, we may provide information to criminal prosecution
            authorities and courts for prosecution purposes.
          </p>
        </div>
        <div className="mt-3">
          <h2 className="text-lg font-medium">5. Cookies</h2>
          <p className="mt-4 text-base leading-7">
            We make use of cookies to store session information, to help you
            sign up for our Services, to protect your data and to provide users
            with a customized Service through identifying unique visitors and
            their respective usage patterns. Cookies are identifiers (miniature
            text files) that can be saved temporarily or permanently on a User’s
            hard drive by the server. Cookies guarantee the consistency of each
            communication process. Temporary cookies can be used to identify
            which visits are all part of a single session. Permanent cookies are
            for pre-recognizing a user, for instance so that individual settings
            can be made. Users are free to decide whether to let their browser
            use cookies. All browser programs allow cookies to be deactivated or
            deleted. When we use cookies no personally identifiable information
            will be collected.
          </p>
          <p className="mt-4 text-base leading-7">
            All information, whether on data processors on our premises or on
            servers acting on our behalf, remains in complete control of us at
            all times.
          </p>
          <p className="mt-4 text-base leading-7">
            If you have set schedule in this site, we will set a temporary
            cookie to determine if your browser accepts cookies. This cookie
            contains no personal data and is discarded when you close your
            browser.
          </p>
          <p className="mt-4 text-base leading-7">
            Embedded content from other websites Articles on this site may
            include embedded content (e.g. videos, images, articles, etc.).
            Embedded content from other websites behaves in the exact same way
            as if the visitor has visited the other website.{" "}
          </p>
          <p className="mt-4 text-base leading-7">
            These websites may collect data about you, use cookies, embed
            additional third-party tracking, and monitor your interaction with
            that embedded content, including tracking your interaction with the
            embedded content if you have an account and are logged in to that
            website.
          </p>
        </div>
        <div className="mt-3">
          <h2 className="text-lg font-medium">6. Security</h2>
          <p className="mt-4 text-base leading-7">
            We employ technical and organizational measures including your data
            (name and email while set schedule) in order to safeguard the
            Service against loss, theft, misuse, destruction, access, alteration
            or the disclosure of your data by unauthorized persons. Despite
            regular controls a complete protection against all dangers is,
            however, not possible. We cannot warrant security for data
            transmitted to our Service because unfortunately, the transmission
            of information via the internet is not completely secure.
            Blankontech; Blankon Technology Solution is based in Indonesia. The
            information we collect is governed by the applicable Legal Intentity
            of Indonesia. If you have any queries about the security of our
            Service, please do not hesitate to contact us at any time on
            info@blankotech.com.
          </p>
        </div>
        <div className="mt-3">
          <h2 className="text-lg font-medium">7. Your rights</h2>
          <p className="mt-4 text-base leading-7">
            Upon request, we shall provide you with the information on the
            stored data relating to your person, free of charge and without
            delay. The information can be provided electronically, if desired.
            In cases regulated by law we are obliged, upon request, to correct,
            block or delete the data stored about you. Please direct all
            requests directly to: info@blankotech.com.
          </p>
        </div>
        <div className="mt-3">
          <h2 className="text-lg font-medium">
            8. How long we retain your data
          </h2>
          <p className="mt-4 text-base leading-7">
            If you set a schedule, the metadata are retained indefinitely. This
            is so we can recognize and approve any schedule automatically
            instead of holding them in a moderation queue.
          </p>
        </div>
        <div className="mt-3">
          <h2 className="text-lg font-medium">9. Changes,</h2>
          <p className="mt-4 text-base leading-7">
            We may change this Privacy Policy from time to time. We will not
            reduce your rights under this Privacy Policy without your explicit
            consent. If we make changes, we will notify you by revising the date
            at the bottom of the policy and, if the changes are significant, we
            will provide you with additional notice (such as adding a statement
            to our Website). To better protect your privacy you may review the
            Privacy Policy whenever you access the Service to stay informed
            about our information practices.
          </p>
          <p className="mt-4 text-base leading-7">
            Our Company keeps its privacy policy under regular review and places
            any updates on this web page. This privacy policy was last updated
            on 20 December 2021.
          </p>
        </div>
      </div>
    </section>
  );
}
