import { Footer } from "./Footer";
import { Navbar } from "./Navbar";

function Home({ children }) {
  return (
    <div className='min-h-screen'>
      <div className='max-w-screen-2xl mx-auto px-4 sm:px-6'>
        <Navbar />
      </div>
      <div>{children}</div>
      <div className='max-w-5xl mx-auto px-4 sm:px-6'>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
