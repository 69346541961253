export const data = [
  {
    id: "1",
    title: "What service do we offer?",
    detail:
      "Developer and Quality Assurance at your service. We started off with a set schedule when we had deadlines, and our team would always be ahead of schedule and never behind.Our team is at least 1-5years experienced in their field, we are concerned about the client's goals and maintain we have good communication with the client and with our team",
  },
  {
      id: "2",
      title: "Is our service globally available?",
      detail: "Yes! until today we have worked with a client from all kind of region"
  },
  {
      id: "3",
      title:"Do we have a partnership program?",
      detail: "We do! became our affiliate and reseller, please visit our blog for further information"
  },
  {
      id: "4",
      title: "How to make Blankontech a partner?",
      detail: "You can register yourself in",
      link: "https://partners.blankontech.com/"
  }
];
