import React from "react";
import Home from "../layout/Home";
import { CoastFaqs } from "./body/coastFaqs";
import { FaqHeader } from "./header/faqHeader";

export class FAQ extends React.Component {
  render() {
    return (
      <Home>
        <FaqHeader />
        <CoastFaqs />
      </Home>
    );
  }
}
