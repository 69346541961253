import CollapseFaqs from "../../components/CollapseFaqs";

export function CoastFaqs() {
  return (
    <section className='my-20 lg:my-32'>
      <div className='container justify-between lg:flex mx-auto'>
        <div className='lg:w-1/3'>
          {/* <select className='lg:hidden'
          value={"Blankontech FAQs"}
          defaultValue={"Blankontech FAQs"}>
            <option>Blankontech FAQs</option>
          </select> */}
          <ul className='hidden space-y-3 list-none sticky-sidebar lg:block'>
            <li>
              <a
                href='/Blankontech-FAQs'
                className='inline-block text-xl transition-all duration-200 ease-in-out border-b-2 border-white hover:font-semibold hover:border-blue-500'
              >
                Blankontech FAQs
              </a>
            </li>
          </ul>
        </div>
        <div className='mt-12 space-y-24 lg:w-2/3 lg:mt-0'>
          <div className='max-w-3xl pt-32 -mt-32'>
            <div className='text-center'>
              <h3 className='font-semibold text-2xl lg:text-4xl'>
                What's Blankontech
              </h3>
              <p className='mt-8 md:my-14 text-lg font-normal leading-relaxed'>
                Blankontech is a software developer partner company, we can help
                you to build and fix your digital product
              </p>
            </div>
            <div className='mt-6'>
              <CollapseFaqs />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
