import React from "react";

export default function LongTextNew({ longtext, handleText, longtextRef, handleSubmit, backSubmit }) {
  return (
    <div ref={longtextRef} className='container mx-auto'>
      <div className='flex h-screen'>
        <div className='m-auto'>
          <div className='sm:w-full md:max-w-3xl mb-5 mt-10 space-y-9 mx-auto'>
            <div className='text-center w-full'>
              <p className='text-white text-3xl mobile:text-xl mt-7 font-normal max-w-2xl w-full mx-auto'>
                Please write down about your project's needs and background as
                full as possible and what we can do for you
              </p>
            </div>
            <div>
              <textarea
                className='bg-white w-full h-96 rounded-lg p-4 border border-purple-600 resize-none placeholder:text-purple-400 placeholder:font-normal placeholder:text-xl'
                type='text'
                placeholder="Hai Blankontech! I need to make a website for my company's product. My idea is create a new system for educators to deliver their lesson in any possible language. I want my product done in 5 months and my budget is over $2400/month"
                name=''
                id=''
                value={longtext}
                onChange={handleText}
              />
            </div>
          </div>
          <div className='w-full flex justify-center mt-20'>
            <button
              onClick={backSubmit}
              type='button'
              className='border mr-4 bg-transparent border-gray-200 p-4 h-14 w-48 text-base font-medium rounded-lg text-gray-200 hover:text-blue-600 hover:bg-white'
            >
              back
            </button>
            <button
              onClick={handleSubmit}
              type='button'
              className='border bg-transparent border-white p-4 h-14 w-48 text-base font-medium rounded-lg text-white hover:text-blue-600 hover:bg-white'
            >
              continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
