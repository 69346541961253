import React from "react";
import MicRecorder from "mic-recorder-to-mp3";
import iconAudio from "../assets/img/voice-record.png"

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export class Vc extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      time:0
    };
    this.startTimer = this.startTimer.bind(this)
    this.stopTimer = this.stopTimer.bind(this)
    this.resetTimer = this.resetTimer.bind(this)
  }


  startTimer() {
    this.timer = setInterval(() => this.setState({
      time: this.state.time + 1
    }), 1000)
    console.log("start")
  }
  stopTimer() {
    clearInterval(this.timer)
    console.log("stop")
  }
  resetTimer() {
    this.setState({time: 0})
    console.log("reset")
  }

  start = () => {
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true });
        }).catch((e) => console.error(e));
    }
  };

  stop = () => {
    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob)
        console.log("blobURL information :" + blobURL);
        this.setState({ blobURL, isRecording: false });
      }).catch((e) => console.log(e));
  };
  

  componentDidMount() {
    navigator.getUserMedia({ audio: true },
      () => {
        console.log('Permission Granted');
        this.setState({ isBlocked: false });
      },
      () => {
        console.log('Permission Denied');
        this.setState({ isBlocked: true })
      },
    );
  }

  formatTime = (timer) => {
  const getSeconds = `0${(timer % 60)}`.slice(-2)
  const minutes = `${Math.floor(timer / 60)}`
  const getMinutes = `0${minutes % 60}`.slice(-2)
  const getHours = `0${Math.floor(timer / 3600)}`.slice(-2)

  return `${getHours} : ${getMinutes} : ${getSeconds}`
}

  

  render() {
    return (
      <div className="container mx-auto">
        <img className={`filter mx-auto ${this.state.isRecording ? 'grayscale-0' : 'grayscale'}`} src={iconAudio} alt="Audio Record" />
        <h2 className="text-white text-3xl font-bold text-center mt-3">{this.formatTime(this.state.time)}</h2>
        <div className="flex flex-row justify-center items-center mt-5">
        <button 
        className="text-white disabled:bg-green-300 bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          onClick={()=> {this.start(); this.startTimer()}} disabled={this.state.isRecording}>
            Start Recording
        </button>
        <button 
        className="text-white disabled:bg-red-400 bg-red-600 hover:bg-red-500 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
        onClick={()=> {this.stop(); this.stopTimer();}} disabled={!this.state.isRecording}>
          Stop
        </button>
        </div>
        <audio className="mx-auto mt-4" src={this.state.blobURL} controls="controls" />
      </div>
    );
  }
}
