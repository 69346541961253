import { Link } from "react-router-dom";
import { IconLayout } from "./IconLayout";


export function Navbar() {
  return (
    <header className="flex justify-between py-6 items-center mobile:flex-col ">
      <div className="justify-start flex lg:w-0 lg:flex-1">
        <a className="flex items-center" href="/">
          <img
            className="h-12 w-12"
            alt=""
            src="https://res.cloudinary.com/blankontech-com/image/upload/v1632285400/blankontech_sp1axc.png"
          />
          <div className="ml-2">
            <h3 className="font-bold text-xl">Blankontech</h3>
          </div>
        </a>
      </div>
      <div className="md:flex">
        <div className="w-full mb-0 mr-0 md:mr-12">
          <Link to={"/options-inquiries"}>
            
              <div className="rounded-xl py-2 px-6 bg-indigo-600 w-auto h-auto text-center mobile:mt-4">
                <p className="text-white text-base font-medium">
                  Tell us about your needs!
                </p>
              </div>
            
          </Link>
        </div>
        <IconLayout
          colorBG="text-black"
          color="text-black"
          className="md:flex hidden"
        />
      </div>
    </header>
  );
}
