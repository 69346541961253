import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function IconLayout({ className, color, colorBG }) {
  return (
    <div className={`space-x-6 items-center ${className}`}>
      <a
        href="https://www.linkedin.com/company/blankon-tech"
        className={`${colorBG}`}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon size="lg" icon={faLinkedin} />
      </a>
      
      <a
        href="https://www.instagram.com/blankon_tech/"
        className={`${colorBG}`}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faInstagram} size="lg" />
      </a>
    </div>
  );
}
