import { IconLayout } from "./IconLayout";

export function NavbarWhite() {
  return (
    <header className="flex justify-between py-6 items-center">
      <div className="justify-start flex lg:w-0 lg:flex-1">
        <a
          className="flex items-center"
          href={`${process.env.REACT_APP_COMPRO}`}
        >
          <img
            className="h-12 w-12"
            alt=""
            src="https://res.cloudinary.com/blankontech-com/image/upload/v1632285400/blankontech_sp1axc.png"
          />
          <div className="ml-2">
            <h3 className="font-medium text-xl text-white">Blankontech</h3>
          </div>
        </a>
      </div>
      <div className="hidden md:flex">
        <div className="w-full mb-0 mr-0 md:mr-12">
          {/* <a href="/">
            <div className="rounded-xl py-2 px-6 bg-indigo-600 w-auto h-auto text-center">
              <p className="text-white text-base font-medium">
                Tell us about your needs!
              </p>
            </div>
          </a> */}
        </div>
        <IconLayout
          colorBG="text-white"
          color="#fff"
          className="md:flex hidden"
        />
      </div>
    </header>
  );
}
