import React, { useEffect, useState } from "react";
import BaseLayout from "./views/BaseLayout";
import { Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./views/PrivacyPolicy";
// import ReactGA from "react-ga";
import { FAQ } from "./views/FAQa";
import Links from "./views/header/Links";
import { SuccessSchedule } from "./views/SuccessSchedule";
import { PageOptions } from "./views/PageOptions";
import { PageOptionsnew } from "./views/PageOptionsnew.jsx";

// ReactGA.initialize("UA-216352703-1");

function App() {
  const [initialized, setInitialized] = useState(false);

  // useEffect(() => {
  //   if (!window.location.href.includes("localhost")) {
  //     ReactGA.initialize("UA-216352703-1");
  //   }
  //   setInitialized(true);
  // }, []);

  useEffect(() => {
    // if (initialized) {
    //   ReactGA.set({ page: window.location.pathname + window.location.search });
    //   ReactGA.pageview(window.location.pathname + window.location.search);
    // }
  }, [initialized]);
  return (
    <>
      <Routes>
        {/* <Route path='/Privacy-policy' element={<PrivacyPolicy />} /> */}
        {/* <Route path="/options-inquiries" element={<PageOptions />} /> */}
        <Route path='/thanks-for-scheduling' element={<SuccessSchedule />} />
        {/* <Route path='/Blankontech-FAQs' element={<FAQ />} /> */}
        {/* <Route
          exact="true"
          path="/options-inquiries"
          element={<PageOptionsnew />}
        /> */}
        <Route
          exact='true'
          path="*"
          element={<PageOptionsnew />}
          status={404}
        />
        {/* <Route exact="true" path="*" element={<BaseLayout />} /> */}
        {/* <Route exact='true' path='/links' element={<Links />} /> */}
      </Routes>
    </>
  );
}

export default App;
